
.finish_ext {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width:100%;
}

.finish_ext h1 {
    font-family: Museo_Slab_500;
    font-size: 36px;
}

.finish_ext p {
    font-family: Museo_Slab_500;
    font-size: 18px;
}

.btn_zone {
    display: flex;
    flex-direction: row;
    gap: 30px;
}

.btn_zone > div {
    flex: 1;
}

.finish_block {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 5%
}

.finish_block > h1 {
    font-weight: bold;
}

.finish_star {
    flex:1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.finish_image {
    flex:4;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat
}


