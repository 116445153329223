.question_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2%;
}

.question_container > h1 {
    font-weight: bolder;
}

.question_container > h1 {
    font-size: 30px;
}

.question_phrase_div {
    box-shadow: '0px 20px 50px -10px rgba(136, 92, 49, 0.15)';
    background: rgba(243, 238, 234, 1);
    border-radius: 25px;
    min-height: 200px;
    width: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    flex:1;
    padding-left: 5%;
    padding-right: 5%;
    gap:10px
}

.question_btn_div {
  display: flex;
  flex-direction: column; 
  gap: 5%;
  flex: 1; 
  align-items: stretch;
}

.question_btn_div > button {
  display: flex;
  flex:1;
  height: 10px;
  min-height: unset;
  max-height: unset;
  padding: 5px;
}

.timer_zone {
    border: 1px solid black
}

.timer_div{
    position: absolute;
    z-index: 9999;
    top: 20px;
    right: 20px;
    height: 70px;
    width: 70px;
    border-radius: 100%;
    box-shadow: 0px 20px 50px -10px rgba(136, 92, 49, 0.15);
}

@media (max-width: 768px) {
    .timer_div {
        height: 20px;
        width: 20px;
        right: unset;
    }
}


.img_zone {
    display: flex;
    justify-content: center; /* Centra l'immagine orizzontalmente */
    align-items: center; /* Centra l'immagine verticalmente */
    width: 100%;
    height: 90%;
    margin-top: 10px;
    margin-bottom: 10px;
    flex: 1;
    overflow: hidden;
    position: relative;
}

.image {
    height: 100%;
    width: auto;
    max-width: 100%;
    position: absolute;
    top: 50%; /* Sposta l'immagine verso il basso del 50% del suo contenitore */
    left: 50%; /* Sposta l'immagine verso destra del 50% del suo contenitore */
    transform: translate(-50%, -50%); /* Centra l'immagine sia orizzontalmente che verticalmente */
}

  