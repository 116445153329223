
.footer_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    padding-right: 5px;
}

.footer_div img,
.footer_div svg {
    height: 50px;
}

@media (max-width: 500px) {
    .footer_div img,
    .footer_div svg {
        height: 25px;
    }
}
