.modalities_container {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.modality_ext {
    display: flex;
    flex-direction: row;
    gap: 100px;
}

.modalities_container > div {
    flex: 1;
}

.modalities_container > div > h1 {
    font-size: 50px;
    font-family: 'Museo_Slab_500', sans-serif;
}

.modalities_container > div > p {
    font-size: 20px;
    font-family: 'Museo_Slab_500', sans-serif;
    padding: 10px;
}

.modalities_container > div:first-child {
    flex: 3;
}

.modalities_container > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (max-width: 500px) {
    .modality_ {
        flex-direction: column;
        height: auto !important;
        gap: 20px !important;
    }

    .modalities_container > div > h1 {
        font-size: 30px;
        font-family: 'Museo_Slab_500', sans-serif;
    }
    
    .modalities_container > div > p {
        font-size: 15px;
        font-family: 'Museo_Slab_500', sans-serif;
        padding: 10px;
    }
}

