.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100dvh; /* Questo assicura che il div occupi l'intera altezza della viewport */
  text-align: center;
}


h1, p, button, span, ul, li {
  font-family: Museo_Slab_500;
}