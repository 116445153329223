
.wrapper_step {
    height: 70%;
    width: 100%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.wrapper_step > div {
    height: 100%;
    display: flex;
}