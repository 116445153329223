.full-height-scrollable {
    height: 100%;         /* Utilizza l'intera altezza del viewport */
    overflow-y: auto;      /* Abilita lo scrolling verticale se il contenuto è più lungo dell'altezza */
    border-radius: 10px;
    padding: 0px 10px 0px 10px;

}

.gallery_title {
    font-size: 1.5rem;
    font-family: Museo_Slab_700;
    font-weight: bolder;
    margin-bottom: 0px;
}