.header_div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 0;
    width: 100%;
    padding: 20px 20px 0px 20px;
}


.header_lan_btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 15px;
    cursor: pointer;
    background-color: rgba(136, 92, 49, 1);
    border-radius: 30px;
    gap: 5px;
    width: 150px;
}

.header_lan_btn p {
    color: white;
    font-family: Museo_Slab_700;
    text-transform: uppercase;
}

.header_lan_btn svg {
    color: white;
}

